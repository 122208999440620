import React from "react";
import { Row, Col } from "reactstrap";
import Ws from "./ws";
const RS = () => {
  return (
    <div className="fixed-bottom">
      <Row className="float-right">
        <Col sm="12" className="pb-4 pr-4">
          <a target="blank" href="https://wa.me/56994190440 ">
            <Ws width={48} height={48}/>
          </a>
        </Col>
        
      </Row>
    </div>
  );
};
export default RS;
