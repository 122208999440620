/*eslint-disable*/
import React, { Fragment } from "react";

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardTitle,
	Container,
	Row,
	Col,
} from "reactstrap";
const Urbanizacion = () => {
  return(
    <div className="section section-image" id="urbanizacion" style={{
      backgroundImage:
        "url(" +
          require("assets/images/proyecto/bg-proyecto.jpeg").default +
        ")",
    }}>
						<Container>
							<Row>
								<Col className="ml-auto mr-auto text-center pb-3" md="8">
									<h2 className="title text-white fw">Proyecto</h2>
								</Col>
							</Row>
							<Row>
								<Col md="6">
									<div className="info">
										<div className="icon text-white">
											<i className="nc-icon icon-square-marker" />
										</div>
										<div className="description">
											<p className="info-title h4 fw">Primera etapa sólo 22 parcelas de agrado de 5.000 m2.</p>
										</div>
									</div>
								</Col>
								<Col md="6">
									<div className="info">
										<div className="icon text-white">
											<i className="nc-icon icon-lightning" />
										</div>
										<div className="description">
											<p className="info-title h4 fw">Urbanización subterránea de Luz lista para empalme independiente. 
											<br/>Derechos de agua superficial para riego por lote.</p>
										</div>
									</div>
								</Col>
								<Col md="6">
									<div className="info">
										<div className="icon text-white">
											<i className="nc-icon icon-mountain" />
										</div>
										<div className="description">
											<p className="info-title h4 fw">Increíbles vistas a la Cordillera.
													<br/>
												Senderos de La Vacada a menos de 10 min.</p>
										</div>
									</div>
								</Col>
								<Col md="6">
									<div className="info">
										<div className="icon text-white">
											<i className="nc-icon icon-map-pin" />
										</div>
										<div className="description">
											<p className="info-title h4 fw">A sólo 50 minutos de Santiago.
											<br/>
												A 20 min de los colegios.
												<br/>
												A 20 min del metro tren y 15 min de acceso sur.</p>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
  )
}

function Proyecto() {
	return (
		<div id="proyecto">
      		<Urbanizacion />      
		</div>
	);
}

export default Proyecto;
