export const photos = [
    {
      src: require('assets/images/galeria/gal1.jpeg').default,
      width: 1280,
      height: 960,
      alt:'',
      title:''
    },
    {
      src: require('assets/images/galeria/gal2.jpeg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/images/galeria/gal3.jpeg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/images/galeria/gal4.jpeg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/images/galeria/gal5.jpg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/images/galeria/gal6.jpg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/images/galeria/gal7.jpg').default,
      width: 1280,
      height: 960,
      title:''
    },
    {
      src: require('assets/images/galeria/gal8.jpg').default,
      width: 1280,
      height: 960,
      title:''
    }
  ];
  